import * as React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AlegereaLocatiei from './AlegereaLocatiei';
import AlegereaSpecialitatii from './AlegereaSpecialitatii';
import AlegereaMedicului from './AlegereaMedicului';
import Review from './Review';
import FinalizareaProgramarii from './FinalizareaProgramarii';
import axios from 'axios';
import AlegereInterval from './AlegereInterval';
import PureModal from 'react-pure-modal';
import 'react-pure-modal/dist/react-pure-modal.min.css';
import { URL } from './Config'
import Loader from 'react-loader-spinner';
import { usePromiseTracker } from "react-promise-tracker";
import { useSyncExternalStore } from 'react';

const LoadingIndicator = props => {
  const { promiseInProgress } = usePromiseTracker();

  return promiseInProgress &&

    <div
      style={{
        width: "100%",
        height: "100",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Loader type="ThreeDots" color="#3f51b5" height="100" width="100" />
    </div>
};



function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [day, month, year].join('/');
}


const steps = [1, 2, 3, 4, 5, 6];

function CheckoutContent(props) {
  const [modal, setModal] = React.useState(false);

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();

  today = mm + '/' + dd + '/' + yyyy;


  var dateinterval = new Date();
  const [interval, setInterval] = React.useState([
    {
      startDate: new Date(),
      endDate: dateinterval.setDate(dateinterval.getDate() + 5),
      key: 'selection'
    }
  ]);

  const [intervalSelectat, setIntervalSelectat] = React.useState({ fromHour: null, toHour: null });
  const [intervaleAPI, setIntervaleAPI] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [location, setLocation] = React.useState("");
  const [locatii, setLocatii] = React.useState([]);
  const [specialization, setSpecialization] = React.useState({ key: "Stomatologie generala", value: "Stomatologie generala", label: "Stomatologie generala", id: 1 });
  const [textModal, setTextModal] = React.useState('');
  const [medic, setMedic] = React.useState(null);
  const [date, setDate] = React.useState(null);
  const [nume, setNume] = React.useState(null);
  const [prenume, setPrenume] = React.useState(null);
  const [telefon, setTelefon] = React.useState(null);
  const [cnp, setCnp] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [numeTutore, setNumeTutore] = React.useState(null);
  const [prenumeTutore, setPrenumeTutore] = React.useState(null);
  const [telefonTutore, setTelefonTutore] = React.useState(null);
  const [observatii, setObservatii] = React.useState(null);
  const [eroare, setEroare] = React.useState(null);
  const [loading,setLoading]=React.useState(false);



  const changeDate = (data) => {
    setActiveStep(activeStep + 1);
    setDate(data.item)
    setMedic({
      id: data.id,
      nume: data.nume
    })
  }

  const handleNext = () => {
    if (activeStep === 0) {
      if (location === '') {
        setModal(true)
        setTextModal('Va rugam sa alegeti o locatie')
      } else {
        setTelefon(props.props.phoneNumberIvr)
        setActiveStep(activeStep + 1);
      }
    }
    if (activeStep === 1) {
      if (specialization === '') {
        setModal(true)
        setTextModal('Va rugam sa alegeti o specialitate')
      } else {
        setActiveStep(activeStep + 1);
      }
    }
    if (activeStep === 2) { setActiveStep(activeStep + 1) }
    if (activeStep === 3) {

      console.log('test')
    }
    if (activeStep === 4) {
      console.log(intervalSelectat)
      if(intervalSelectat.formHour==null){
        setModal(true)
        setTextModal('Va rugam sa alegeti un interval orar')
      }else{
        setActiveStep(activeStep + 1);
      }
     
    }
    if (activeStep === 5) {

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");



      var raw = JSON.stringify({
        "cnp": cnp,
        "firstName": nume,
        "lastName": prenume,
        "phoneNumber": telefon,
        "idLocation": location.id,
        "email": email,
        "medicId": medic.id,
        "dateTime": formatDate(date) + " " + intervalSelectat.value,
        "duration": 45,
        "observations": observatii,
        "firstNameParinte": numeTutore,
        "lastNameParinte": prenumeTutore,
        "phoneNumberParinte": telefonTutore
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      setLoading(true);

      fetch(URL + "/programare", requestOptions)
        .then(response => response.text())
        .then(result => {
          var raspuns = JSON.parse(result)
          if (raspuns[0].valoare == "Succes") {
            var requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow',
              mode: 'no-cors'
            };
            var myHeaders = new Headers();
            myHeaders.append("Access-Control-Allow-Origin", "true");
            //console.log(intervalSelectat)
            fetch(`https://app.2waysms.io/smsapi/index?key=463CFD147B059D&campaign=211&routeid=3&type=text&contacts=${telefon}&senderid=3861&msg=Confirmam programarea din data de ${formatDate(date)}, ora ${intervalSelectat.value}, la Cabinetul ${location.label} medic ${medic.nume} Va multumim si va dorim o zi frumoasa!`, requestOptions)
              .then(response => response.text())
              .then(result => {setLoading(false);})
              .catch(error => console.log('error', error));
            setActiveStep(activeStep + 1);
          } else {
            setLoading(false);
            setEroare("Ceva nu a mers bine , va rugam incercati din nou")
          }
        })
        .catch(error => console.log('error', error));



    }
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };


  React.useEffect((props) => {

    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(URL + "/lista_locatii", requestOptions)
      .then(response => response.text())
      .then(result => {
        var obj = JSON.parse(result)
        obj[0].forEach((data) => {
          var state = locatii;
          var obj = {
            value: data.Name,
            label: data.Name,
            id: data.id
          }
          state.push(obj);
          //console.log(state)
          setLocatii(state)
          //console.log(state)
        })
      })
      .catch(error => console.log('error', error));



  }, []);

  return (
    <div>
      <React.Fragment>
        <CssBaseline />
        <PureModal
          isOpen={modal}
          onClose={() => {
            setModal(false);
            return true;
          }}
        >
          <span style={{ display: 'block', wordBreak: 'break-word', marginTop: 20, marginBottom: 20, paddingLeft: 10, paddingRight: 10, textAlign: 'center' }}>{textModal}</span>
        </PureModal>
        <Container component="main" >
          <Paper variant="outlined" >
            <Stepper activeStep={activeStep} alternativeLabel={true} orientation="horizontal">
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel></StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  {eroare == null ?
                    <div>
                      <Typography variant="h5" gutterBottom>
                        <center><div style={{ marginRight: 10, marginLeft: 10 }}>  Programarea a fost efectuata!</div></center>
                      </Typography>
                      <Typography variant="subtitle1">
                        <center><div style={{ marginRight: 10, marginLeft: 10 }}>
                          In scurt timp veti primi un SMS de confirmare a programarii. Va multumim !
                        </div></center>
                      </Typography>
                    </div>
                    : <div>{eroare}</div>}
                </React.Fragment>
              ) : (
                <React.Fragment>

                  {activeStep === 0 ? <AlegereaLocatiei
                    locatii={locatii}
                    setLocatii={setLocatii}
                    locatiaSelectata={location}
                    setLocation={setLocation}
                  /> : null}
                  {activeStep === 1 ?
                    <AlegereaSpecialitatii

                      locationId={location}
                      specialitateaSelectata={specialization}
                      setSpecialization={setSpecialization}


                    /> : null}
                  {activeStep === 2 ?

                    <AlegereInterval
                      interval={interval}
                      setInterval={setInterval}

                    />
                    : null}
                  {activeStep === 3 ?

                    <AlegereaMedicului

                      interval={interval}
                      location={location}
                      specialization={specialization}
                      data={date}
                      medic={medic}
                      setMedic={setMedic}
                      setIntervaleAPI={setIntervaleAPI}
                      changeDate={changeDate}

                    />
                    : null}
                  {activeStep === 4 ?

                    <FinalizareaProgramarii
                      data={date}
                      intervaleAPI={intervaleAPI}
                      intervalSelectat={intervalSelectat}
                      setIntervalSelectat={setIntervalSelectat}
                      nume={nume}
                      medic={medic}
                      setNume={setNume}
                      prenume={prenume}
                      setPrenume={setPrenume}
                      telefon={telefon}
                      setTelefon={setTelefon}
                      cnp={cnp}
                      setCnp={setCnp}
                      email={email}
                      setEmail={setEmail}
                      numeTutore={numeTutore}
                      setNumeTutore={setNumeTutore}
                      prenumeTutore={prenumeTutore}
                      setPrenumeTutore={setPrenumeTutore}
                      telefonTutore={telefonTutore}
                      setTelefonTutore={setTelefonTutore}
                      observatii={observatii}
                      setObservatii={setObservatii}
                    />
                    : null}

                  {activeStep === 5 ?
                    <div>
                      {loading === true ? <LoadingIndicator /> :
                        <Review
                          location={location}
                          medic={medic}
                          nume={nume}
                          date={date}
                          intervalSelectat={intervalSelectat}
                          prenume={prenume}
                          telefon={telefon}
                          cnp={cnp}
                          email={email}
                          numeTutore={numeTutore}
                          prenumeTutore={prenumeTutore}
                          telefonTutore={telefonTutore}
                          observatii={observatii}

                        />
                      }
                    </div>
                    : null}
                  {activeStep < 1 && (
                    <Button style={{ marginLeft: 15, marginTop: 15, marginBottom: 35 }}></Button>

                  )}

                  {activeStep >= 1 && (
                    <Button style={{ marginLeft: 15, marginTop: 15, marginBottom: 35 }} onClick={handleBack}>
                      Back
                    </Button>

                  )}
                  {activeStep != 3 ?
                    <div style={{ float: 'right' }}>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 3, ml: 1 }}
                        style={{ marginRight: 15, marginTop: 15, marginBottom: 15 }}
                      >
                        {activeStep === steps.length - 1 ? 'Confirma programarea' : 'Next'}
                      </Button>
                    </div>
                    : null}

                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
        </Container>
      </React.Fragment>
    </div>
  );
}

export default function Checkout(props) {
  return <CheckoutContent props={props} />;
}
