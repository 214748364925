import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import logo from './download.jpg';
import { addDays, format, isWeekend } from 'date-fns';
import { Calendar } from 'react-date-range';

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
});

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
        //console.log(d)

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    return [day, month, year].join('/');
}


export default function ImgMediaCard(props) {
    const classes = useStyles();
    
    function customDayContent(day) { 
        var dateComponenta = new Date(day);
        var formatedDates=[];
        props.zi.forEach(value=>{
            
            formatedDates.push(value)
        })
        var extraDot = null;
   
        if (formatedDates.includes(formatDate(dateComponenta))) {
            
            extraDot = (
                <div
                    style={{
                        height: "5px",
                        width: "5px",
                        borderRadius: "100%",
                        background: '#33ff45',
                        position: "absolute",
                        top: 2,
                        right: 2,
                    }}
                    
                />
            )
        }
        return (
            <div>
                {extraDot}
                <span>{format(day, "d")}</span>
            </div>
        )
    }

    return (
        <center style={{ marginBottom: 15 }}>
            <Card className={classes.root} >
                <center>
                    <CardActionArea >
                        <CardMedia
                            component="img"
                            alt="Logo"
                            image={logo}
                            title="Contemplative Reptile"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                {props.nume}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                            
                            </Typography>
                            <Calendar
                                style={{ marginLeft: -15 }}
                                dayContentRenderer={customDayContent}
                                onChange={item => props.changeDate({id:props.id,nume:props.nume,item:item})}
                                date={props.date}
                            />
                        </CardContent>
                    </CardActionArea>

                </center>
            </Card>
        </center>
    );
}