import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from 'react-select';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file


function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('/');
}



export default function DateleClientului(props) {
  
    const [intervaleSelect, setIntervaleSelect] = React.useState(null);

    React.useEffect(() => {
        const array = props.intervaleAPI;
        console.log(array)
        array.forEach(element => {
            if (formatDate(props.data) == element.ziua) {
                // setIntervaleSelect(element.locuri_libere)
                var arr = [];
                element.locuri_libere.forEach((element2, index) => {
                    var obj = {
                        key: index,
                        value: element2.fromHour ,
                        label: element2.fromHour ,
                        formHour: element2.fromHour   
                    }
                    arr.push(obj);
                })
                setIntervaleSelect(arr)
            }

        })
    }, [])

    return (
        <React.Fragment >
            {console.log(props)}
            <Typography variant="h6" gutterBottom style={{ marginBottom: 20 }}>
                <center>
                    Finalizarea programarii la medicul <br /><b> {props.medic.nume}</b> in data  <br /> <b>{formatDate(props.data)}</b>
                </center>
            </Typography>
            <FormControl style={{ marginLeft: 15, width: '95%', marginBottom: 15 }}>
                <b><center style={{ marginTop: 10, marginBottom: 10, fontSize: 20 }}>Alege Intervalul Orar</center></b>
                <Select value={props.intervalSelectat} options={intervaleSelect} placeholder="Alege Ora Consultului"  onChange={(e) => { props.setIntervalSelectat(e) }} style={{ marginTop: 144 }}  />
                <b><center style={{ marginTop: 10, marginBottom: 10, fontSize: 20 }}>Si completeaza datele personale </center></b>
                <TextField value={props.nume} onChange={(e) => { props.setNume(e.target.value) }} id="standard-basic-nume" label="Nume " style={{ marginBottom: 10, marginTop: 10 }} />
                <TextField value={props.prenume} onChange={(e) => { props.setPrenume(e.target.value) }} id="standard-basic-prenume" label="Prenume" style={{ marginBottom: 10 }} />
                <TextField value={props.telefon} id="standard-basic-telefon" label="Telefon" style={{ marginBottom: 10 }} />
                <TextField value={props.cnp} onChange={(e) => { props.setCnp(e.target.value) }} id="standard-basic-cnp" label="CNP" style={{ marginBottom: 10 }} />
                <TextField value={props.email} onChange={(e) => { props.setEmail(e.target.value) }} id="standard-basic-email" label="E-mail" style={{ marginBottom: 10 }} />
                <TextField value={props.numeTutore} onChange={(e) => { props.setNumeTutore(e.target.value) }} id="standard-basic-numeTutore" label="Nume Parinte/Tutore" style={{ marginBottom: 10 }} />
                <TextField value={props.prenumeTutore} onChange={(e) => { props.setPrenumeTutore(e.target.value) }} id="standard-basic-prenumeTutore" label="Prenume Parinte/Tutore" style={{ marginBottom: 10 }} />
                <TextField value={props.telefonTutore} onChange={(e) => { props.setTelefonTutore(e.target.value) }} id="standard-basic-telefonTutore" label="Telefon Parinte/Tutore" style={{ marginBottom: 10 }} />
                <TextField value={props.observatii} onChange={(e) => { props.setObservatii(e.target.value) }} id="standard-basic-observatii" label="Observatii" style={{ marginBottom: 10 }} />
            </FormControl>


        </React.Fragment>
    );
}
