import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import MedicCard from './MedicCard';
import { usePromiseTracker } from "react-promise-tracker";
import { trackPromise } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import PureModal from 'react-pure-modal';
import { URL } from './Config';

const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();

    return promiseInProgress &&

        <div
            style={{
                width: "100%",
                height: "100",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <Loader type="ThreeDots" color="#3f51b5" height="100" width="100" />
        </div>
};



function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('/');
}



export default function AlegereaMedicului(props) {

    const [modal, setModal] = React.useState(false);
    const [intervale, setIntervale] = React.useState([]);
    const [loading, setIsLoading] = React.useState(false);
    React.useEffect(() => {
        setIsLoading(true)

        var startDate = new Date(props.interval[0].startDate);
        var reqstartDate = formatDate(startDate)
        var endDate = new Date(props.interval[0].endDate);
        var reqendDate = formatDate(endDate)
        //console.log(props.location)
        trackPromise(

            axios.get(
                URL + '/lista_locuri_libere?locatie=' + props.location.value + "&dateStart=" + reqstartDate + "&dateEnd=" + reqendDate,
                {
                    headers: {
                        token: "\"" + props.token + "\"",
                        tokenApp: props.tokenApp
                    }
                }
            ).then((value) => {
                var final_arr = [];
                props.setIntervaleAPI(value.data)

                if (value.data.length >= 1) {
                    final_arr.push({
                        medicId: value.data[0].medicId,
                        nume_medic: value.data[0].nume_medic,
                        ziua: [value.data[0].ziua]
                    })
                    value.data.forEach(element => {
                        final_arr.forEach(elementfinal => {

                            if (elementfinal.medicId == element.medicId && !elementfinal.ziua.includes(element.ziua)) {
                                elementfinal.ziua.push(element.ziua)
                            }

                        })

                    })
                }
                //console.log(final_arr)
                setIntervale(final_arr)

            }).then(() => {
                setIsLoading(false)
            })).catch(function (error) {
                if (error.response) {
                    //console.log(error.response.status);
                    if (error.response.status === 403) {
                        setModal(true)
                    }
                }
            })

    }, [])



    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                <center>
                    <div style={{ marginBottom: 15 }}>
                        Alegerea medicului
                    </div>
                </center>
            </Typography>
            {loading === true ? <LoadingIndicator /> :
                <div>
                    {intervale.length == 0 ? <div><center>Ne pare rau, nu exista medici disponibili in intervalul ales</center></div> :
                        <div>
                            {intervale.map((value, index) => {
                                return (<MedicCard key={index} nume={value.nume_medic} id={value.medicId} zi={value.ziua} date={props.date} changeDate={props.changeDate} setMedic={props.setMedic} />)
                            })}
                        </div>
                    }
                </div>}
        </React.Fragment>
    );
}
