
import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from 'react-select';
import siglaAssmb from './siglaAssmb.jpg'


export default function AddressForm(props) {

  return (
    <div>
      {console.log(props)}
       <div>
        <center>
          <img src={siglaAssmb} style={{ width: 100, height: 40 }} alt="Logo" />
        </center>
      </div>
      <br></br>
      <Typography variant="h6" gutterBottom>
        <center> Alegerea locatiei</center>
      </Typography>
     
      <br></br>
      <FormControl style={{ marginLeft: 10, width: '95%', marginBottom: 15 }}>
        <Select options={props.locatii} placeholder="Search" displayEmpty value={props.locatiaSelectata} onChange={(e) => { props.setLocation(e) }} />
      </FormControl>
    </div>
  );
}
