import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}


export default function Review(props) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        <center>Detalii Programare</center>
      </Typography>


      <List >
        <center>
          <ListItemText style={{ marginLeft: 15, width: '95%', marginBottom: 15 }} />
          <Typography variant="subtitle1" style={{ marginLeft: 15, width: '95%', marginBottom: 15 }} >
            Locatie : <b>{props.location.label}</b>
          </Typography>
          <Typography variant="subtitle1" style={{ marginLeft: 15, width: '95%', marginBottom: 15 }} >
            Medic : <b>{props.medic.nume}</b>
          </Typography>
          <Typography variant="subtitle1" style={{ marginLeft: 15, width: '95%', marginBottom: 15 }} >
            Data si ora programare  : <br></br><b>{formatDate(props.date)} {props.intervalSelectat.label}</b>
          </Typography>
          <Typography variant="subtitle1" style={{ marginLeft: 15, width: '95%', marginBottom: 15 }} >
            Nume : <br></br><b>{props.nume}</b>
          </Typography>
         
          <Typography variant="subtitle1" style={{ marginLeft: 15, width: '95%', marginBottom: 15 }} >
            Prenume  : <br></br><b>{props.prenume}</b>
          </Typography>
          <Typography variant="subtitle1" style={{ marginLeft: 15, width: '95%', marginBottom: 15 }} >
            CNP  : <br></br><b>{props.cnp}</b>
          </Typography>
          <Typography variant="subtitle1" style={{ marginLeft: 15, width: '95%', marginBottom: 15 }} >
            Email  : <br></br><b>{props.email}</b>
          </Typography>
          <Typography variant="subtitle1" style={{ marginLeft: 15, width: '95%', marginBottom: 15 }} >
            Telefon  : <br></br><b>{props.telefon}</b>
          </Typography>
          
          <Typography variant="subtitle1" style={{ marginLeft: 15, width: '95%', marginBottom: 15 }} >
            Nume Parinte / Tutore  : <br></br><b>{props.numeTutore}</b>
          </Typography>
          <Typography variant="subtitle1" style={{ marginLeft: 15, width: '95%', marginBottom: 15 }} >
            Prenume Parinte / Tutore   : <br></br><b>{props.prenumeTutore}</b>
          </Typography>
          <Typography variant="subtitle1" style={{ marginLeft: 15, width: '95%', marginBottom: 15 }} >
            Telefon Tutore  : <br></br><b>{props.telefonTutore}</b>
          </Typography>
        
          <Typography variant="subtitle1" style={{ marginLeft: 15, width: '95%', marginBottom: 15 }} >
            Observatii  : <br></br><b>{props.observatii}</b> 
          </Typography>
        </center>

      </List>

    </React.Fragment>
  );
}
