import React from 'react';
import Checkout from "./Checkout";
import { Route, BrowserRouter as Router } from 'react-router-dom';
import qs from 'qs';
import { useJwt } from "react-jwt";
import { useEffect } from "react";

function App() {

  var token = qs.parse(window.location.search, { ignoreQueryPrefix: true }).token
  const { decodedToken, isExpired } = useJwt(token);


  return (
    <div>
      {decodedToken === null ? null :
        <div>
          {isExpired === true || token == undefined ? <div> Tokenul a expirat , va rugam sa sunati din nou in Assmb Medbot </div> :
            <Checkout tokenApp={token} phoneNumberIvr={decodedToken.payload}/>
          }
        </div>
      }
    </div>
  );
}

export default App;
